// flow
import React from "react"

import CancellationOnlyClose from "../CancellationOnlyClose"
import CancellationModalWrapper from "../CancellationModalWrapper"

type Props = {|
  cancelSubscriptionData: string,
  questions: Object,
  questionButtons: QuestionButtonsType,
  progressValue?: number,
  handleDismiss?: () => void | Promise<void>,
  handleClose?: () => void | Promise<void>,
  handleCancelSubscription?: () => void | Promise<void>,
  hasFeedbackArea?: Boolean,
  suspendedData?: SuspendedSubscriptionType,
  isProcessing: Boolean,
  isImmediatelyCanceled: Boolean,
  lang: string,
  handleOpenRestartSubscription?: () => void,
|}

const CancellationSuspendedModal = ({
  cancelSubscriptionData = {},
  isImmediatelyCanceled = false,
  handleOpenRestartSubscription = () => {},
  suspendedData = {},
  lang,
  handleDismiss = () => {},
}: Props) => {
  return (
    <CancellationModalWrapper
      isEnableBack={false}
      isConfirmSubscriptionModal={true}
      handleDismiss={handleDismiss}
      classNames={{
        content:
          "p-0 md:w-640 sm:w-600 h-max-content sm:m-0 sm:top-1/2 sm:left-1/2 mx-16 my-16 w-cancel-subscription-modal rounded-lg inset-x-50-16 inset-y-50-16",
      }}
    >
      <CancellationOnlyClose
        data={cancelSubscriptionData}
        btnDismissLabel={cancelSubscriptionData.close_button_label.text}
        handleClose={handleDismiss}
        suspendedData={suspendedData}
        isImmediatelyCanceled={isImmediatelyCanceled}
        lang={lang}
        handleOpenRestartSubscription={handleOpenRestartSubscription}
      />
    </CancellationModalWrapper>
  )
}

export default CancellationSuspendedModal
