// flow
import React, { createElement } from "react"
import type { CancellationOnlyCloseType } from "../../types/CancellationOnlyCloseType"
import type { SuspendedSubscriptionType } from "../../types/SuspendedSubscriptionType"

import { RichText, Elements } from "prismic-reactjs"
import {
  Button,
  linkResolver,
  propsWithUniqueKey,
  ROUTES,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: CancellationOnlyCloseType,
  btnDismissLabel?: string,
  handleClose: () => void | Promise<void>,
  suspendedData?: SuspendedSubscriptionType,
  isImmediatelyCanceled?: Boolean,
  lang?: string,
  handleOpenRestartSubscription?: () => void | Promise<void>,
|}

const CancellationOnlyClose = ({
  data = {},
  btnDismissLabel = "",
  handleClose,
  suspendedData = {},
  isImmediatelyCanceled,
  lang,
  handleOpenRestartSubscription,
}: Props) => {
  const {
    suspended_content = { raw: [{ type: "", text: "", spans: [] }] },
  } = data
  let content = suspended_content.raw[0]

  // Add more param ?step=restart to link subscribe
  const htmlSerializerAddParamUrl = (type, element, children, key) => {
    let props = {}
    const paramRestart = "?step=restart"

    if (type === Elements.hyperlink) {
      // If it is immediate cancel, to restart, it should be navigated to MEMBERSHIP
      // For normal way, it will executed to restart subscription via popup
      props = Object.assign({
        href: isImmediatelyCanceled
          ? ROUTES(lang).MEMBERSHIP
          : element.data.url || `${linkResolver(element.data)}${paramRestart}`,
        onClick: () => {
          handleOpenRestartSubscription()
          handleClose()
        },
      })

      return createElement("a", propsWithUniqueKey(props, key), children)
    } else {
      return null
    }
  }

  content = {
    ...content,
    text: suspended_content.text
      .replace("@email", suspendedData.email)
      .replace("@expirationDate", suspendedData.expirationDate),
  }

  // Correct position of link text subscribe (it was changed because of replacing email and expiration date above)
  const link = "subscribe" // text string need add link
  const start =
    content.text.indexOf("subscribe button") > -1
      ? content.text.indexOf("subscribe button")
      : content.text.indexOf("Subscribe“")
  let spans = content.spans
  spans[1] = {
    ...spans[1],
    start: start,
    end: start + link.length,
  }
  content = {
    ...content,
    spans: spans,
  }

  return (
    <div className="flex flex-col md:block justify-between h-full md:w-progress-bar only-close-cancel">
      <div className="sm:px-60 px-16 py-48 cancel-success-title">
        <RichText
          render={[content]}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerAddParamUrl}
        />
      </div>
      {btnDismissLabel && (
        <div className="border-t border-gray-200 py-24 w-full">
          <Button
            handleOnClick={() => handleClose()}
            className="text-base w-full underline text-gray-800 decoration-gray-800"
          >
            {btnDismissLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CancellationOnlyClose
